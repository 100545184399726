$red: #C8102E;

.value_on_red {
    font-size: 16px !important;
    font-weight: bold;
    margin: 15px;
    background-color: $red;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    width: auto;
    padding: 0 !important;
    line-height: 16px !important;
    padding: 6px 2px !important;
    color: #ffffff !important;

    a {
        color: #ffffff !important;
        padding: 6px !important;
    }
}

.portfolio-img a {
    &:hover {
        cursor: pointer !important;
    }
}

.quotation {
    text-indent: -.3em;
}

.shadow {
    box-shadow: 0 0 8px rgba(0,0,0,.45) !important;
}

.soft_shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.45);
}
